var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-page bg-light"},[_c('main',{staticClass:"main"},[_c('div',{staticClass:"infos"},[_c('div',{staticClass:"bg"},[_c('div',{staticClass:"infos-item"},[_c('img',{staticClass:"headPortrait",attrs:{"src":_vm.info.headimage || _vm.defaultHeadimage}}),_c('div',{staticClass:"name"},[_c('div',{staticClass:"nickname"},[_vm._v(_vm._s(_vm.info.nickname))]),(_vm.info.sex == 2)?_c('img',{staticClass:"sex",attrs:{"src":require("@/assets/images/female.png")}}):_c('img',{staticClass:"sex",attrs:{"src":require("@/assets/images/male.png")}})]),(_vm.info.address)?_c('div',{staticClass:"address"},[_c('img',{attrs:{"src":require("@/assets/images/address-gray.png")}}),_c('span',{staticClass:"address-text"},[_vm._v(_vm._s(_vm.info.address))])]):_vm._e(),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.info.honor))]),_c('el-dropdown',{on:{"command":_vm.handleCommand}},[_c('div',{staticClass:"btn-click"},[_vm._v("上传作品")]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"1"}},[_vm._v("图片")]),_c('el-dropdown-item',{attrs:{"command":"2"}},[_vm._v("视频")])],1)],1)],1)])]),_c('div',{staticClass:"detail"},[_c('ul',{staticClass:"tabs"},[_vm._l((_vm.tabs),function(n,index){return _c('li',{key:index,class:{
            active: _vm.currentIndex === index,
            'left-border': _vm.currentIndex === index - 1,
            'right-border': _vm.currentIndex === index + 1 && _vm.currentIndex !== 1,
            'first-border':
              _vm.currentIndex !== 0 && _vm.currentIndex === 1 && index === 0,
            'first-left-border':
              _vm.currentIndex !== 0 && _vm.currentIndex !== 1 && index === 0
          },on:{"click":function($event){return _vm.changeTab(index, n)}}},[_vm._v(" "+_vm._s(n.label)+" "),(_vm.currentIndex === index)?_c('div',{staticClass:"currentLine"}):_vm._e()])}),_c('span',{staticClass:"empty-line",class:{ 'empty-border-left': _vm.currentIndex === _vm.tabs.length - 1 }})],2),_c('div',{staticClass:"contents"},[(_vm.list.length > 0)?_c('div',_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"contents-item",class:{ 'comment-height': _vm.tabType === '评论' },on:{"click":function($event){return _vm.handleDetail(item)}}},[_c('div',{staticClass:"item-left"},[_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.handlePersonCenter(item)}}},[_c('el-avatar',{attrs:{"size":52,"src":item.headimage}})],1),_c('div',{staticClass:"info"},[_c('p',{staticClass:"con"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(item.nickname))]),(_vm.tabType == '粉丝')?_c('span',[_vm._v(" 关注了你")]):_vm._e(),(
                      _vm.tabType == '点赞' ||
                        _vm.tabType == '转发' ||
                        _vm.tabType == '评论' ||
                        _vm.tabType == '收藏'
                    )?_c('span',[_vm._v("的原创作品《"+_vm._s(item.resource_title)+"》")]):_vm._e()]),_c('p',{staticClass:"time"},[_vm._v(_vm._s(_vm.getTime(item.create_time)))]),(_vm.tabType === '评论')?_c('div',{staticClass:"comment"},[_c('div',{staticClass:"sanjiao"}),_c('div',{staticClass:"comment-con"},[_c('div',{staticClass:"content"},[_vm._v(_vm._s(item.comment_info.content))]),_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.getTime(item.comment_info.create_time))+" ")])])]):_vm._e()])]),(_vm.tabType !== '粉丝' && _vm.tabType !== '关注')?_c('div',{staticClass:"item-right-image"},[_c('el-image',{staticClass:"cover-image",attrs:{"src":item.resource_url,"fit":"cover"},on:{"contextmenu":_vm.handleMouse}})],1):_c('div',{staticClass:"item-right-btn"},[(_vm.tabType === '粉丝')?_c('div',{staticClass:"fensi"},[(item.follow_status == 1)?_c('div',{staticClass:"fensi_g"},[_vm._v(" 互相关注 ")]):_c('div',{staticClass:"fensi_h",on:{"click":function($event){return _vm.handleFollow(item.other_user_id)}}},[_vm._v(" 回粉 ")])]):_vm._e(),(_vm.tabType === '关注')?_c('div',{staticClass:"fensi"},[(item.follow_status == 1)?_c('div',{staticClass:"fensi_g"},[_vm._v(" 互相关注 ")]):_c('div',{staticClass:"guanzhu"},[_vm._v("已关注")])]):_vm._e()])])}),0):_c('div',{staticClass:"no-data"},[_vm._v("暂时还没有相关信息")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }