export default {
  data() {
    return {
      query: {},
      personQuery: {}, //个人中心参数
      // 表格分页的相关配置
      pagination: {
        layout: "prev, pager, next",
        pageSizes: ["10", "20", "50", "100"],
        pageSize: 80, // 对应defaultPageSize
        defaultPageSize: 80, // 不写默认10，为了测试少数据分页可修改为1
        total: 0,
        currentPage: 1
      },
      // 搜索的的时候是否显示加载
      //   isNeedLoadng: true,
      // 重置的时候是否直接搜索
      isNeedResetSearch: false
    };
  },
  methods: {
    toggleAdvanced() {
      this.advanced = !this.advanced;
    },
    // 搜索
    handleSearch() {
      this.pagination.currentPage = 1;
      this.getPage();
    },
    // 重置
    handleReset() {
      this.query = this.$options.data().query;
      if (this.isNeedResetSearch) {
        this.handleSearch();
      }
    },
    // 分页、筛选变化时触发
    handleCurrentChange(currentPage) {
      console.log(9999999, currentPage);
      // 判断切换当前页面数值 需要重置到第一页
      const pagination = { currentPage: 1 };
      pagination.currentPage = currentPage;
      Object.assign(this.pagination, pagination);
      this.getPage();
    },
    //个人中心列表分页、筛选变化时触发
    handlePersonalCurrentChange(currentPage) {
      console.log(8888888, currentPage);
      // 判断切换当前页面数值 需要重置到第一页
      const pagination = { currentPage: 1 };
      pagination.currentPage = currentPage;
      Object.assign(this.pagination, pagination);
      this.getPersonPage();
    },
    // 处理要请求数据
    getPage() {
      console.log("getPagegetPagegetPage", this.pagination);
      const { pageSize, currentPage } = this.pagination;
      const data = {
        ...this.query,
        limit: pageSize,
        page: currentPage
      };
      this.getList(data);
    },
    //个人中心列表单独处理
    getPersonPage() {
      console.log("getPersonPagegetPersonPagegetPersonPage", this.pagination);
      const { pageSize, currentPage } = this.pagination;
      const data = {
        ...this.personQuery,
        limit: pageSize,
        page: currentPage
      };
      this.getPersonalList(data);
    }
  }
};
