export default {
  data() {
    return {
      isToLogin: false
    };
  },
  methods: {
    navigatorToLogin() {
      this.isToLogin = localStorage.getItem("user_id");
      if (this.isToLogin) {
        return true;
      } else {
        this.$confirm("请您先登录！！！", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success"
        })
          .then(() => {
            this.$router.push({ name: "login", params: { type: "login" } });
            return false;
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消"
            });
          });
      }
    }
  }
};
