<template>
  <div class="news-page bg-light">
    <main class="main">
      <div class="infos">
        <div class="bg">
          <div class="infos-item">
            <img
              :src="info.headimage || defaultHeadimage"
              class="headPortrait"
            />
            <div class="name">
              <div class="nickname">{{ info.nickname }}</div>
              <img
                src="@/assets/images/female.png"
                class="sex"
                v-if="info.sex == 2"
              />
              <img src="@/assets/images/male.png" class="sex" v-else />
            </div>
            <div class="address" v-if="info.address">
              <img src="@/assets/images/address-gray.png" />
              <span class="address-text">{{ info.address }}</span>
            </div>
            <div class="title">{{ info.honor }}</div>
            <el-dropdown @command="handleCommand">
              <div class="btn-click">上传作品</div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1">图片</el-dropdown-item>
                <el-dropdown-item command="2">视频</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div class="detail">
        <ul class="tabs">
          <li
            v-for="(n, index) in tabs"
            :key="index"
            :class="{
              active: currentIndex === index,
              'left-border': currentIndex === index - 1,
              'right-border': currentIndex === index + 1 && currentIndex !== 1,
              'first-border':
                currentIndex !== 0 && currentIndex === 1 && index === 0,
              'first-left-border':
                currentIndex !== 0 && currentIndex !== 1 && index === 0
            }"
            @click="changeTab(index, n)"
          >
            {{ n.label }}
            <div class="currentLine" v-if="currentIndex === index"></div>
          </li>
          <span
            class="empty-line"
            :class="{ 'empty-border-left': currentIndex === tabs.length - 1 }"
          ></span>
        </ul>
        <div class="contents">
          <div v-if="list.length > 0">
            <div
              v-for="(item, index) in list"
              :key="index"
              class="contents-item"
              :class="{ 'comment-height': tabType === '评论' }"
              @click="handleDetail(item)"
            >
              <div class="item-left">
                <div @click.stop="handlePersonCenter(item)">
                  <el-avatar :size="52" :src="item.headimage"></el-avatar>
                </div>

                <div class="info">
                  <p class="con">
                    <span class="name">{{ item.nickname }}</span>
                    <span v-if="tabType == '粉丝'"> 关注了你</span>
                    <!-- {{ tabType == "粉丝" ? "关注" : tabType }} -->
                    <span
                      v-if="
                        tabType == '点赞' ||
                          tabType == '转发' ||
                          tabType == '评论' ||
                          tabType == '收藏'
                      "
                      >的原创作品《{{ item.resource_title }}》</span
                    >
                  </p>
                  <p class="time">{{ getTime(item.create_time) }}</p>
                  <div class="comment" v-if="tabType === '评论'">
                    <div class="sanjiao"></div>
                    <div class="comment-con">
                      <div class="content">{{ item.comment_info.content }}</div>
                      <div class="time">
                        {{ getTime(item.comment_info.create_time) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="item-right-image"
                v-if="tabType !== '粉丝' && tabType !== '关注'"
              >
                <el-image
                  class="cover-image"
                  @contextmenu="handleMouse"
                  :src="item.resource_url"
                  fit="cover"
                ></el-image>
              </div>
              <div class="item-right-btn" v-else>
                <div class="fensi" v-if="tabType === '粉丝'">
                  <div class="fensi_g" v-if="item.follow_status == 1">
                    互相关注
                  </div>
                  <div
                    class="fensi_h"
                    v-else
                    @click="handleFollow(item.other_user_id)"
                  >
                    回粉
                  </div>
                </div>
                <div class="fensi" v-if="tabType === '关注'">
                  <div class="fensi_g" v-if="item.follow_status == 1">
                    互相关注
                  </div>
                  <div class="guanzhu" v-else>已关注</div>
                </div>
              </div>
            </div>
          </div>
          <div class="no-data" v-else>暂时还没有相关信息</div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import dayjs from "dayjs";
import pageMixin from "@/mixins/tbPage";
import toLogin from "@/mixins/toLogin";
export default {
  name: "index",
  mixins: [pageMixin, toLogin],
  data() {
    return {
      query: {}, //参数
      defaultHeadimage: require("../../assets/images/headPortrait.png"),
      info: {
        headimage: require("../../assets/images/headPortrait.png"),
        nickname: "你的名字是什么",
        address: "四川成都",
        honor: "2021-7封面摄影师",
        sex: "1"
      },
      tabs: [
        {
          label: "点赞",
          value: 5
        },
        {
          label: "转发",
          value: 3
        },
        {
          label: "评论",
          value: 4
        },
        {
          label: "收藏",
          value: 2
        },
        {
          label: "粉丝",
          value: 1
        },
        {
          label: "关注",
          value: 6
        }
      ],
      tabType: "点赞",
      currentIndex: 0,
      list: [
        // {
        //   name: "小米",
        //   headimage: require("../../assets/images/headPortrait.jpeg"),
        //   time: "2020-11-12T15:59:59.999Z",
        //   resource_title: "高山滑雪",
        //   resource_url:
        //     "http://gjdl960.oss-cn-beijing.aliyuncs.com/202202/1.jpeg"
        // },
        // {
        //   name: "小米",
        //   headimage: require("../../assets/images/headPortrait.jpeg"),
        //   time: "2020-11-12T15:59:59.999Z",
        //   resource_title: "高山滑雪",
        //   resource_url:
        //     "http://gjdl960.oss-cn-beijing.aliyuncs.com/202202/1.jpeg"
        // },
        // {
        //   name: "小米",
        //   headimage: require("../../assets/images/headPortrait.jpeg"),
        //   time: "2020-11-12T15:59:59.999Z",
        //   resource_title: "高山滑雪",
        //   resource_url:
        //     "http://gjdl960.oss-cn-beijing.aliyuncs.com/202202/1.jpeg"
        // },
        // {
        //   name: "小米",
        //   headimage: require("../../assets/images/headPortrait.jpeg"),
        //   time: "2020-11-12T15:59:59.999Z",
        //   resource_title: "高山滑雪",
        //   resource_url:
        //     "http://gjdl960.oss-cn-beijing.aliyuncs.com/202202/1.jpeg"
        // },
        // {
        //   name: "小米",
        //   headimage: require("../../assets/images/headPortrait.jpeg"),
        //   time: "2020-11-12T15:59:59.999Z",
        //   resource_title: "高山滑雪",
        //   resource_url:
        //     "http://gjdl960.oss-cn-beijing.aliyuncs.com/202202/1.jpeg"
        // }
      ]
    };
  },
  async mounted() {
    let info = JSON.parse(localStorage.getItem("user_info"));
    this.info = info;
    const user_id = localStorage.getItem("user_id");
    this.user_id = user_id;
    const { type } = this.$route.query;
    this.type = type;
    if (type == 1) {
      this.currentIndex = 4;
      this.tabType = "粉丝";
    } else if (type == 2) {
      this.currentIndex = 3;
      this.tabType = "收藏";
    } else if (type == 4) {
      this.currentIndex = 2;
      this.tabType = "评论";
    } else if (type == 5) {
      this.currentIndex = 0;
      this.tabType = "点赞";
    } else if (type == 6) {
      this.currentIndex = 5;
      this.tabType = "关注";
    } else {
      this.currentIndex = 0;
      this.tabType = "点赞";
    }
    this.query = {
      type,
      user_id
    };
    // 调取列表方法
    this.getPage();
    //调取点赞消息已读接口
    this.$api.getEditpushmsg({ user_id: this.user_id, type }).then(res => {
      console.log(res);
    });
    setTimeout(() => {
      this.$store.dispatch("news/getTotal");
    }, 300);
  },
  methods: {
    handleMouse(e) {
      e.preventDefault();
    },
    changeTab(index, row) {
      this.currentIndex = index;
      this.tabType = row.label;
      const type = row.value;
      this.type = type;
      this.query = {
        type,
        user_id: this.user_id
      };
      this.getPage();
      //调取消息已读接口
      this.$api.getEditpushmsg({ user_id: this.user_id, type }).then(res => {
        console.log(res);
      });
      setTimeout(() => {
        this.$store.dispatch("news/getTotal");
      }, 300);
    },
    getTime(time) {
      return dayjs(time).format("YYYY/MM/DD HH:mm");
    },
    async getList(params) {
      console.log("getListgetList", params);
      const { data } = await this.$api.getNewsList(params);
      if (data.code === 1) {
        this.list = data?.data;
      }
    },
    handleCommand(command) {
      if (command == 1) {
        this.$router.push({
          name: "uploadProductionImg"
        });
      } else {
        this.$router.push({
          name: "uploadProductionVideo"
        });
      }
    },
    handleFollow(other_user_id) {
      console.log("添加关注other_user_id", other_user_id);
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      const user_id = this.user_id; //当前用户id
      this.$api.postFollow({ user_id, other_user_id }).then(res => {
        if (res.data.code === 1) {
          this.$message.success(res.data.msg);
          this.query = {
            type: this.type,
            user_id: this.user_id
          };
          this.getPage();
          return;
        } else {
          this.$message.error(res.data.msg);
          return;
        }
      });
    },
    handlePersonCenter(item) {
      this.$router.push({
        name: "personalCenter",
        query: { PageUserId: item.other_user_id }
      });
    },
    handleDetail(item) {
      console.log(item.resource_type);
      if (this.tabType == "关注" || this.tabType == "粉丝") {
        return false;
      } else {
        if (item.resource_type == "2") {
          this.$router.push({
            name: "videoPreview",
            query: { id: item.resource_id }
          });
        } else {
          this.$router.push({
            name: "picturePreview",
            query: { id: item.resource_id }
          });
        }
      }
    }
  }
};
</script>

<style lang="scss">
.news-page {
  padding: 50px 0px;
  color: #000000;
  min-height: 100%;
  main {
    display: flex;
    margin: 0 auto;
  }
  .infos {
    width: 365px;
    height: 466px;
    margin-right: 23px;
    background-image: url("../../assets/images/news-1.png");
    background-size: 365px 466px;
    position: relative;
    .bg {
      position: absolute;
      top: 27px;
      left: 33px;
      width: 268px;
      height: 377px;
      background-image: url("../../assets/images/news-2.png");
      background-size: 268px 377px;
      .infos-item {
        position: absolute;
        width: 250px;
        top: 45px;
        left: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        font-weight: 300;
        overflow: hidden;
        .headPortrait {
          width: 111px;
          height: 111px;
          border: 2px solid #ffffff;
          border-radius: 50%;
        }
        .name {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
          margin: 30px 0 22px;
          .nickname {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 150px;
            display: inline-block;
          }
          .sex {
            width: 16px;
            height: 14px;
            margin-left: 5px;
          }
        }
        .address {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #000000;
          img {
            width: 11px;
            height: 15px;
            margin-right: 5px;
          }
          .address-text {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 150px;
            display: inline-block;
          }
        }
        .title {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #ac412d;
          margin: 15px 0 25px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 150px;
        }
        .btn-click {
          width: 135px;
          height: 35px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          background: #e60021;
          border-radius: 7px;
          text-align: center;
          line-height: 35px;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
  .detail {
    flex: 1;
    background: #ffffff;
    box-shadow: 16px 26px 17px 0px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    padding: 20px 40px 0;
    .tabs {
      display: flex;
      li {
        // border-bottom: 6px solid #000000;
        padding: 0 37px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #7d7d7d;
        cursor: pointer;
        position: relative;
        .currentLine {
          width: 85%;
          height: 6px;
          background: #e60021;
          border-radius: 10px;
          margin-top: 10px;
          position: relative;
          &:after {
            content: " ";
            position: absolute;
            right: -8px;
            top: 0;
            width: 6px;
            height: 6px;
            background: #e60021;
            border-radius: 6px;
          }
        }
        &:after {
          content: " ";
          position: absolute;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 6px;
          background: #000000;
        }
      }
      .active {
        font-family: PingFang SC;
        color: #000000;
        font-weight: 500;
        &:after {
          display: none;
        }
      }
      .left-border {
        &:after {
          border-radius: 10px 0 0 10px;
        }
      }
      .first-border {
        &:after {
          border-radius: 10px;
        }
      }
      .first-left-border {
        &:after {
          border-radius: 10px 0 0 10px;
        }
      }
      .right-border {
        &:after {
          border-radius: 0 10px 10px 0;
        }
      }
      .empty-line {
        flex: 1;
        display: inline-block;
        position: relative;
        &:after {
          content: " ";
          position: absolute;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 6px;
          background: #000000;
          border-radius: 0 10px 10px 0;
        }
      }
      .empty-border-left {
        &:after {
          border-radius: 10px;
        }
      }
    }
    .contents {
      .contents-item {
        box-sizing: border-box;
        padding: 20px 0;
        border-bottom: 1px solid rgba(172, 172, 172, 0.23);
        display: flex;
        justify-content: space-between;
        height: 169px;
        &:last-child {
          border-bottom: none;
        }
        .item-left {
          display: flex;
          align-items: flex-start;
          flex: 1;
          padding-top: 20px;
          .info {
            margin-left: 15px;
            flex: 1;
            .time {
              color: #b5b5b6;
              font-size: 13px;
              font-family: PingFang SC;
              font-weight: 500;
              margin-top: 17px;
              text-align: left;
            }
            .con {
              font-size: 17px;
              font-family: PingFangSC-Regular;
              font-weight: 500;
              .name {
                font-size: 17px;
                font-family: PingFangSC-Medium;
                font-weight: 500;
              }
            }
          }
          .comment {
            margin-top: 10px;
            .sanjiao {
              width: 0;
              height: 0;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-bottom: 8px solid #ebecec;
              margin-left: 12px;
            }
            .comment-con {
              background: #ebecec;
              border-radius: 7px;
              min-height: 50px;
              padding: 10px;
              margin-right: 30px;
              .content {
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #000000;
                padding-top: 10px;
              }
              .time {
                color: #b5b5b6;
                font-size: 13px;
                text-align: left;
                font-family: PingFang SC;
                font-weight: 500;
              }
            }
          }
        }
        .item-right-image {
          padding-top: 10px;
          align-self: center;
          .cover-image {
            width: 112px;
            height: 84px;
          }
        }
        .item-right-btn {
          padding-top: 10px;
          .fensi {
            color: #ffffff;
            font-family: PingFang SC;
            font-weight: 600;
            font-size: 14px;
            div {
              height: 22px;
              line-height: 22px;
              text-align: center;
              cursor: pointer;
              border-radius: 10px;
            }
            .fensi_g {
              width: 77px;
              background: #bfbfbf;
            }
            .fensi_h {
              width: 53px;
              background: #e60021;
            }
            .guanzhu {
              width: 77px;
              background: #ffffff;
              border: 1px solid #e60021;
              color: #e60021;
              line-height: 22px;
            }
          }
        }
      }
      .comment-height {
        height: 200px;
      }
      .no-data {
        font-size: 16px;
        text-align: center;
        padding: 60px 0;
      }
    }
  }
}
// 媒体查询 小于等于某个宽度
@media screen and (max-width: 1339px) {
  .news-page {
    .main {
      width: 1200px;
    }
  }
}
@media screen and (min-width: 1340px) and (max-width: 1921px) {
  .news-page {
    .main {
      width: 1312px;
    }
  }
}
@media screen and (min-width: 1921px) {
  .news-page {
    .main {
      width: 1312px;
    }
  }
}
</style>
<style lang="scss" scoped>
.el-dropdown-menu {
  min-width: 80px;
  padding: 0;
  .el-dropdown-menu__item {
    font-size: 13px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: #595757;
    height: 50px;
    line-height: 50px;
    padding: 0;
    margin: 0 8px;
    text-align: center;
    border-bottom: 1px solid #a0a0a0;
    &:hover {
      background: transparent;
      color: #595757;
    }
    &:nth-child(2) {
      border-bottom: none;
    }
  }
}
</style>
